export default function useGoogleMapsStyles() {
    const styles = [
        {
            featureType: 'administrative.country',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'administrative.locality',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'administrative.neighborhood',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#BEE2A2',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'geometry.fill',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    color: '#FF0000',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    color: '#C8CEDC',
                },
            ],
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#E3E6EF',
                },
            ],
        },
        {
            featureType: 'landscape.natural',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    color: '#E3E6EF',
                },
            ],
        },
        {
            featureType: 'landscape.natural.landcover',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#BEE2A2',
                },
            ],
        },
        {
            featureType: 'landscape.natural.terrain',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#BEE2A2',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#BEE2A2',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#BEE2A2',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.business',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.business',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#BEE2A2',
                },
            ],
        },
        {
            featureType: 'poi.business',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.business',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.government',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.government',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#BEE2A2',
                },
            ],
        },
        {
            featureType: 'poi.government',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.medical',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.medical',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#C8CEDC',
                },
            ],
        },
        {
            featureType: 'poi.medical',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#BEE2A2',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.place_of_worship',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.place_of_worship',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#C8CEDC',
                },
            ],
        },
        {
            featureType: 'poi.place_of_worship',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.school',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.school',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#C8CEDC',
                },
            ],
        },
        {
            featureType: 'poi.school',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.sports_complex',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.sports_complex',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#BEE2A2',
                },
            ],
        },
        {
            featureType: 'poi.sports_complex',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit.station',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#C8CEDC',
                },
            ],
        },
    ];
    return { styles };
}
