import { Loader } from '@googlemaps/js-api-loader';
import { onUnmounted } from 'vue';
import { LocationState } from '@/types';
import { translate } from '@/plugins/translate';

export default function useMarkers(map: any) {
    const markers: any[] = [];

    function addMarker(infoWindowContent: { title: string; description: string; address: string; locationState: LocationState }, pos: { lat: number; lng: number }) {
        const infoWindowSizes = infoWindowContent.description ? { height: 'h-47', yOffset: 238 } : { height: 'h-36', yOffset: 194 };
        const availability = translate(`provider_translations.${infoWindowContent.locationState}`);
        const icons =
            infoWindowContent.locationState === LocationState.Available
                ? { active: '/icons/ic_pin_available_active.png', inactive: '/icons/ic_pin_available_inactive.png' }
                : { active: '/icons/ic_pin_maybe_available_active.png', inactive: '/icons/ic_pin_maybe_available_inactive.png' };
        const google = (window as any).google;

        const infoWindow = new google.maps.InfoWindow({
            content: `<div class="divide-y w-80 ${infoWindowSizes.height} overflow-auto">
            <div relative>
                <div class="absolute -top-4 -left-5">
                    <img class="w-11 h-11" src="${icons.active}" />
                </div>
                <div class="space-y-1 mb-4">
                    <h4 class="font-medium text-xl">${infoWindowContent.title}</h4>
                    <div class="flex space-x-1">
                        ${
                            infoWindowContent.address?.length
                                ? `<span>
                        <img src="/icons/ic_location.svg" class="h-4" />
                    </span>
                    <span class="opacity-70 text-secondary">
                        ${infoWindowContent.address}
                    </span>`
                                : ''
                        }
                    </div>
                </div>
            </div>
            <div class="space-y-3">
                <div class="mt-4">
                    <span class="rounded-xl text-base bg-opacity-10 bg-green-600 text-green-600 px-2 py-1">${availability}</span>
                </div>
                <div>
                    <p class="text-base">${infoWindowContent.description}</p>
                </div>
            </div>
        </div>`,
            pixelOffset: new google.maps.Size(173, infoWindowSizes.yOffset),
        });

        const marker = new google.maps.Marker({
            position: pos,
            map,
            icon: {
                url: icons.inactive,
                scaledSize: new google.maps.Size(45, 45),
            },
        });

        markers.push(marker);

        marker.addListener('click', () => {
            infoWindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
            });
        });

        google.maps.event.addListener(map, 'click', (event: any) => {
            infoWindow.close();
        });

        const escapeHandler = () => {
            infoWindow.close();
        };
        document.addEventListener('keydown', escapeHandler);

        return { window: infoWindow, marker };
    }

    function removeAllMarkers() {
        markers.forEach((currentMarker) => {
            currentMarker.setMap(null);
        });
    }

    return { addMarker, removeAllMarkers };
}
