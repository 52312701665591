
import { defineComponent } from 'vue';

export default defineComponent({
    computed: {
        highlightedTitle() {
            const translatedThis = this as any;
            return (
                translatedThis.$t('partners.title')?.replace(
                    translatedThis.$t('partners.title_highlight'),
                    `<span class="text-red-600">
                        ${translatedThis.$t('partners.title_highlight')}
                        </span>`
                ) || ''
            );
        },
    },
});
