import { onMounted, ref } from 'vue';

/* Requires each section to have an element with sectionHeaderClassName and a unique ID */
export default function useScrollSections(sectionHeaderClassName: string) {
    const currentOverlappingSectionId = ref<string>('');
    const sectionHeaders = ref<HTMLElement[]>([]);

    const initCurrentOverlappingSectionId = () => {
        if (sectionHeaders.value.length) {
            currentOverlappingSectionId.value = sectionHeaders.value[0].id;
        }
    };

    const initScrollObservers = () => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio === 1) {
                        currentOverlappingSectionId.value = entry.target.id;
                    }
                });
            },
            {
                threshold: 1,
            }
        );

        sectionHeaders.value.forEach((currentSection) => {
            observer.observe(currentSection);
        });
    };

    onMounted(() => {
        sectionHeaders.value = Array.from(document.querySelectorAll(`.${sectionHeaderClassName}`));
        initCurrentOverlappingSectionId();
        initScrollObservers();
    });

    return { currentOverlappingSectionId };
}
