import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative w-full bg-white" }
const _hoisted_2 = { class: "flex py-2 pl-4 pr-2 space-x-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "my-auto" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex-1 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("button", {
      type: "button",
      class: "w-full rounded-md relative",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSelectClicked && _ctx.onSelectClicked(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          class: "ml-auto border",
          src: _ctx.value[_ctx.itemImage]
        }, null, 8, _hoisted_3),
        _createElementVNode("span", _hoisted_4, [
          _createElementVNode("img", {
            class: _normalizeClass([{ 'transform rotate-180': _ctx.expandDropdown }, "transition-transform my-auto"]),
            src: "/icons/ic_dropdown.svg"
          }, null, 2)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([[_ctx.expandDropdown ? 'border max-h-120 z-10' : 'max-h-0', _ctx.dropdownPosition === 'bottom' ? 'top-12' : 'bottom-12'], "bg-white transition-max-height absolute w-52 right-0 rounded-md divide-y overflow-y-hidden flex flex-col"])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("button", {
            key: item,
            type: "button",
            class: "p-4 hover:bg-gray-100 font-semibold text-left flex space-x-4",
            onClick: _withModifiers(($event: any) => (_ctx.onItemClicked(item)), ["stop"])
          }, [
            _createElementVNode("img", {
              class: "border",
              src: item[_ctx.itemImage]
            }, null, 8, _hoisted_6),
            _createElementVNode("span", _hoisted_7, _toDisplayString(item[_ctx.itemText]), 1)
          ], 8, _hoisted_5))
        }), 128))
      ], 2)
    ])), [
      [_directive_click_outside, () => (_ctx.expandDropdown = false)]
    ])
  ]))
}