import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/plugins/router';
import '@/assets/css/index.css';
import ApiClient from '@/plugins/store/APIClient';
import translate from '@/plugins/translate';

ApiClient.getDynamicContent();
ApiClient.getStaticContent();

createApp(App).use(router).use(translate).mount('#app');
