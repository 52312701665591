import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen relative" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_HeartstarterMap = _resolveComponent("HeartstarterMap")!
  const _component_About = _resolveComponent("About")!
  const _component_Collaborators = _resolveComponent("Collaborators")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Navbar, { "current-overlapping-section-id": _ctx.currentOverlappingSectionId }, null, 8, ["current-overlapping-section-id"]),
    _createVNode(_component_HeartstarterMap),
    _createVNode(_component_About),
    _createElementVNode("img", {
      src: _ctx.$t('fullwidth_image'),
      class: "h-84 md:h-136 lg:h-175 w-full"
    }, null, 8, _hoisted_2),
    _createVNode(_component_Collaborators),
    _createVNode(_component_Footer)
  ]))
}