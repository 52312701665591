import { onUnmounted, ref, watch, Ref } from 'vue';
import { translate } from '@/plugins/translate';

export function useGeolocation(map: Ref<any>) {
    const coords = ref({ latitude: 0, longitude: 0 });
    const isSupported = 'navigator' in window && 'geolocation' in navigator;
    let watcher: number | null = null;
    if (isSupported) {
        watcher = navigator.geolocation.watchPosition((position) => {
            coords.value = position.coords;
        });
    }

    let centerInfoWindows: any[] = [];

    function centerMapOnLocation(position: { lat: number; lng: number }) {
        if (map.value) {
            map.value.setCenter(position);
            map.value.setZoom(Math.max(10, map.value.zoom));
            let infoWindowId = '';
            const characters = 'abcdefghijklmnopqrstuvwxyz';
            const charactersLength = characters.length;
            for (let i = 0; i < 7; i++) {
                infoWindowId += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            const infoWindow = new window.google.maps.InfoWindow({
                content: `<div id="${infoWindowId}" class="bg-white text-black">${translate('you_are_here')}</div>`,
                position,
            });

            infoWindow.open({
                map: map.value,
            });
            centerInfoWindows.forEach((currentInfoWindow) => currentInfoWindow.close());
            centerInfoWindows = [];
            centerInfoWindows.push(infoWindow);
            setTimeout(() => {
                document.querySelector(`#${infoWindowId}`)?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add('anchor-enabled');
            }, 100);
        }
    }

    const buttomLeftControlDiv = document.createElement('div');
    map.value.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(buttomLeftControlDiv);
    let centerButton: null | HTMLButtonElement = null;

    watch(
        () => coords,
        (newValue) => {
            if (newValue.value.latitude !== 0 || newValue.value.longitude !== 0) {
                if (!centerButton) {
                    centerButton = document.createElement('button');
                    centerButton.type = 'button';
                    centerButton.classList.add('p-2', 'bg-white', 'rounded-md', 'mb-2');
                    const centerImage = document.createElement('img');
                    centerImage.src = '/icons/ic_center.png';
                    centerButton.appendChild(centerImage);
                    buttomLeftControlDiv.appendChild(centerButton);
                    centerButton.addEventListener('click', () => centerMapOnLocation({ lat: coords.value.latitude, lng: coords.value.longitude }));
                }
            }
        },
        { deep: true, immediate: true }
    );

    onUnmounted(() => {
        if (watcher) {
            navigator.geolocation.clearWatch(watcher);
        }
    });
    return { isSupported, coords };
}
