
import { defineComponent, ref } from 'vue';
import ClickOutside from '@/util/directives/ClickOutside';
import useEscapable from '@/util/composables/useEscapable';

export default defineComponent({
    directives: { ClickOutside },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        value: {
            required: true,
        },
        itemText: {
            type: String,
            required: true,
        },
        itemImage: {
            type: String,
            required: true,
        },
        itemValue: {
            type: String,
            required: true,
        },
        dropdownPosition: {
            type: String,
            default: 'bottom',
        },
    },
    emits: ['update:value'],
    setup(props, context) {
        const expandDropdown = ref<boolean>(false);
        const close = () => {
            expandDropdown.value = false;
        };
        const onSelectClicked = () => {
            expandDropdown.value = !expandDropdown.value;
        };
        const onItemClicked = (item: any) => {
            context.emit('update:value', item);
            expandDropdown.value = false;
        };
        const { onUnmounted } = useEscapable(close);
        return { onUnmounted, expandDropdown, onSelectClicked, onItemClicked };
    },
});
