
import { defineComponent } from 'vue';

export default defineComponent({
    computed: {
        highlightedSaveLivesTitle() {
            const translatedThis = this as any;
            return (
                translatedThis
                    .$t('help_saving_lifes.title')
                    ?.replace(translatedThis.$t('help_saving_lifes.title_highlight'), `<span class="text-red-600">${translatedThis.$t('help_saving_lifes.title_highlight')}</span>`) || ''
            );
        },
        highlightedDownloadAppTitle() {
            const translatedThis = this as any;
            return (
                translatedThis.$t('download_app.title')?.replace(translatedThis.$t('download_app.title_highlight'), `<span class="text-red-600">${translatedThis.$t('download_app.title_highlight')}</span>`) ||
                ''
            );
        },
    },
});
