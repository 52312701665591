import { state } from '@/plugins/store';

export function translate(key: string) {
    return key.split('.').reduce((accum, current) => {
        if (accum && accum[current]) {
            return accum[current];
        }
        return null;
    }, state.value.staticContent as { [key: string]: any });
}

export default {
    install: (app: any, options: any) => {
        app.config.globalProperties.$t = translate;
    },
};
