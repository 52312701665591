import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen relative flex flex-col" }
const _hoisted_2 = { class: "pt-36 px-4 pb-20 flex flex-1" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Navbar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "policy-content container mx-auto",
        innerHTML: _ctx.$t('policy')
      }, null, 8, _hoisted_3)
    ]),
    _createVNode(_component_Footer)
  ]))
}