
import { defineComponent } from 'vue';
import CustomImageSelect from '@/components/ui/CustomImageSelect.vue';
import { Language } from '@/types';
import useLanguages from '@/util/composables/useLanguages';
import { state } from '@/plugins/store';
import APIClient from '@/plugins/store/APIClient';

export default defineComponent({
    components: { CustomImageSelect },
    props: {
        currentOverlappingSectionId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            showSidebar: false,
            selectedLanguage: null as null | Language,
            languages: useLanguages().languages,
        };
    },
    computed: {
        sections(): any {
            return [
                {
                    id: 'find-heart-starter',
                    selected: this.$props.currentOverlappingSectionId === 'find-heart-starter',
                    text: (this as any).$t('menu.find_defibrillators'),
                },
                {
                    id: 'download-app',
                    selected: this.$props.currentOverlappingSectionId === 'download-app',
                    text: (this as any).$t('menu.download_app'),
                },
                {
                    id: 'collaborators',
                    selected: this.$props.currentOverlappingSectionId === 'collaborators',
                    text: (this as any).$t('menu.partners'),
                },
            ];
        },
    },
    watch: {
        selectedLanguage: {
            immediate: false,
            handler(newValue: Language, prevValue: null | Language) {
                if (prevValue && prevValue !== newValue) {
                    state.value.activeLanguageCode = newValue.code;
                    APIClient.getStaticContent();
                    APIClient.getDynamicContent();
                }
            },
        },
    },
    created() {
        this.selectedLanguage = this.languages.find((currentLanguage) => currentLanguage.code === state.value.activeLanguageCode)!;
    },
});
