
import { defineComponent, onMounted, ref, watch } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import useMarkers from '@/util/composables/useMarkers';
import useGoogleMapsStyles from '@/util/composables/useGoogleMapsStyles';
import { LocationState, Location } from '@/types';
import { state } from '@/plugins/store';
import { useGeolocation } from '@/util/composables/useGeolocation';

export default defineComponent({
    setup() {
        const loader = new Loader({ apiKey: process.env.VUE_APP_MAPS_API_KEY });
        const map = ref<null | HTMLDivElement>(null);
        const { styles } = useGoogleMapsStyles();
        const googleMap = ref();

        onMounted(async () => {
            await loader.load();
            googleMap.value = new window.google.maps.Map(map.value as HTMLElement, {
                center: { lat: 67.6, lng: -46 },
                zoom: 4.75,
                styles,
            });
            useGeolocation(googleMap);

            const { addMarker, removeAllMarkers } = useMarkers(googleMap.value);
            let pendingMarkers: Location[] = [];

            function replaceAllMarkers(locations: Location[]) {
                removeAllMarkers();
                locations?.forEach((currentLocation) => {
                    if (currentLocation.state !== LocationState.NotAvailable) {
                        addMarker(
                            {
                                title: currentLocation.title,
                                description: currentLocation.description,
                                address: currentLocation.address,
                                locationState: currentLocation.state,
                            },
                            { lat: Number(currentLocation.coordinates.latitude), lng: Number(currentLocation.coordinates.longitude) }
                        );
                    }
                });
            }
            watch(
                () => state.value.dynamicContent,
                (newValue) => {
                    if (state.value.staticContent && newValue) {
                        replaceAllMarkers(newValue);
                    } else if (newValue) {
                        pendingMarkers = newValue;
                    }
                },
                { deep: true, immediate: true }
            );

            watch(
                () => state.value.staticContent,
                () => {
                    if (pendingMarkers.length) {
                        replaceAllMarkers(pendingMarkers);
                        pendingMarkers = [];
                    }
                },
                { deep: true, immediate: true }
            );
        });

        return { map };
    },
});
