import { RouteRecordRaw } from 'vue-router';
import Home from '@/views/home/Index.vue';
import Privacy from '@/views/privacy/Index.vue';

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy,
    },
];
