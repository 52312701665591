
import { defineComponent } from 'vue';
import Navbar from '@/components/layout/Navbar.vue';
import HeartstarterMap from '@/views/home/HeartstarterMap.vue';
import About from '@/views/home/About.vue';
import Collaborators from '@/views/home/Collaborators.vue';
import Footer from '@/components/layout/Footer.vue';
import useScrollSections from '@/util/composables/useScrollSections';

export default defineComponent({
    components: { Navbar, HeartstarterMap, About, Collaborators, Footer },
    setup() {
        const { currentOverlappingSectionId } = useScrollSections('scroll-section');
        return { currentOverlappingSectionId };
    },
});
