import { Language } from '@/types';

export default function useLanguages() {
    const languages = [
        {
            code: 'da',
            text: 'Dansk',
            image: '/icons/DK.svg',
        },
        {
            code: 'en',
            text: 'English',
            image: '/icons/ENG.svg',
        },
        {
            code: 'kl',
            text: 'Kalaallisut',
            image: '/icons/KL.svg',
        },
    ] as Language[];
    return { languages };
}
