import axios from 'axios';
import { state } from './index';

export default class ApiClient {
    private static BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

    public static async getStaticContent() {
        state.value.staticContent = await axios.request({ method: 'get', url: `${ApiClient.BACKEND_URL}/frontpage?language=${state.value.activeLanguageCode}` }).then((res) => res.data);
    }

    public static async getDynamicContent() {
        state.value.dynamicContent = await axios.request({ method: 'get', url: `${ApiClient.BACKEND_URL}/locations?language=${state.value.activeLanguageCode}` }).then((res) => res.data);
    }
}
